export default [
  {
    path: '/dashboard',
    name: 'transfer-dashboard',
    component: () => import('@/views/pages/transfer/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "หน้าหลัก",
        },
        {
          text: "รายการยอดโอนเงิน",
          active: true,
        },
      ],
    },
  },
]
