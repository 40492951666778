export default [
  {
    path: '/account-lists',
    name: 'account-lists',
    component: () => import('@/views/pages/management/accountBank/accountLists/List.vue'),
    meta: {
      pageTitle: "บัญชีธนาคารทั้งหมด",
      breadcrumb: [
        {
          text: "บัญชีธนาคาร",
        },
        {
          text: "รายการบัญชีทั้งหมด",
          active: true
        },
      ],
    },
  },
  {
    path: '/account-add',
    name: 'account-add',
    component: () => import('@/views/pages/management/accountBank/accountForm/Add.vue'),
    meta: {
      pageTitle: "เพิ่มบัญชีธนาคาร",
      breadcrumb: [
        {
          text: "บัญชีธนาคาร",
        },
        {
          text: "รายการบัญชีทั้งหมด",
          to: '/account-lists'
        },
        {
          text: "เพิ่มบัญชีธนาคาร",
          active: true,
        },
      ],
    },
  },
  {
    path: '/account-edit/:id',
    name: 'account-edit',
    component: () => import('@/views/pages/management/accountBank/accountForm/Edit.vue'),
    meta: {
      pageTitle: "แก้ไขบัญชีธนาคาร",
      breadcrumb: [
        {
          text: "บัญชีธนาคาร",
        },
        {
          text: "รายการบัญชีทั้งหมด",
          to: '/account-lists'
        },
        {
          text: "แก้ไขบัญชีธนาคาร",
          active: true,
        },
      ],
    },
  },
  {
    path: '/account-detail/:id',
    name: 'account-detail',
    component: () => import('@/views/pages/management/accountBank/accountForm/Detail.vue'),
    meta: {
      pageTitle: "รายละเอียดบัญชีธนาคาร",
      breadcrumb: [
        {
          text: "บัญชีธนาคาร",
        },
        {
          text: "รายการบัญชีทั้งหมด",
          to: '/account-lists'
        },
        {
          text: "รายละเอียด",
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-existings',
    name: 'user-existings',
    component: () => import('@/views/pages/management/userExisting/userLists/List.vue'),
    meta: {
      pageTitle: "ผูกบัญชี",
      breadcrumb: [
        {
          text: "User",
        },
        {
          text: "ตั้งค่าบัญชีผูกกับผู้ใช้งานปัจจุบัน",
          active: true
        },
      ],
    },
  },
]
